
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { OrderDetails } from '@/models/Order';
import { generalStore } from '@/store';
import useOrder from '@/modules/useOrder';

export default defineComponent({
    components: {},
    name: 'OrderRemarksEdit',
    data() {
        return {};
    },
    async setup() {
        const route = useRoute();
        const id = route.params.id.toString();
        const remarks = ref<string>('');

        const { saveStoreOrder } = useOrder();
        const order = ref(new OrderDetails());
        order.value = generalStore.getters.getNewOrder;

        async function getOrderRemarks() {
            if (id !== '0') {
                swal.showLoading();
                const apiResult = await api.GetOrderRemarks(id);
                await Q.delay(400);
                if (apiResult.data?.error) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.data?.error
                    });
                    swal.close();
                    return '';
                }
                swal.close();
                return apiResult.data?.remarks || '';
            } else {
                return order.value.remarks;
            }
        }
        remarks.value = await getOrderRemarks();

        async function saveOrderRemarks() {
            if (id !== '0') {
                swal.showLoading();
                const apiResult = await api.SaveOrderRemarks(id, remarks.value);
                await Q.delay(400);
                if (apiResult.data?.error) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.data?.error
                    });
                    return;
                }
                remarks.value = apiResult.data?.remarks || '';
                router.push({ name: 'order', params: { id: id } });
            } else {
                order.value.remarks = remarks.value;
                await saveStoreOrder(order.value);
                router.push({ name: 'neworder' });
            }
        }

        function Cancel() {
            if (id !== '0') {
                router.push({ name: 'order', params: { id: id } });
            } else {
                router.push({ name: 'neworder' });
            }
        }

        function checkDescription() {
            if (remarks.value && remarks.value.length > 1000) {
                remarks.value = remarks.value.substring(0, 1000);
            }
        }
        return { remarks, saveOrderRemarks, Cancel, checkDescription };
    }
});
